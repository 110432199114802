<template>
  <div>
    <NavBar style="max-height=50px;margin-bottom: 10px;" :username="currentUserName"></NavBar>
    <v-app>
      <div style="background-color: #f5faff; height: 92vh !important;">
         <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height=50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10">

             <h3 class="EditSuggestionh3Class">Edit Suggestion</h3>

            <v-card style="padding:10px; margin:20px 50px; height: 70vh; overflow: overflow:scroll; overflow-x: hidden;">

           <div style="margin: 20px 50px;">
            <div style='display: flex; flex-direction: column;'>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 800" align="left">
                  <span class="css-title"> Suggestion Name</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-text-field
                    label="Name"
                    solo
                    v-model="suggestion_name"
                  ></v-text-field>
                </div>
              </div>

              <div style='display: flex; flex-direction: row; padding: 10px;'>
                <div  style="flex:1;font-weight: 800" align="left">
                  <span class="css-title"> Description</span>
                </div>
                <div style="width: 50%" align="right">
                  <v-textarea
                    label="Enter Description"
                    solo
                    v-model="short_description"
                  ></v-textarea>
                </div>
              </div>
              <div>
                <v-btn style="width:50%;background-color: #1467BF;" color="primary" @click="editSuggestion" :disabled="isSuggestionClicked || !(suggestion_name != '' && short_description.trim() != '')">Apply Changes</v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</v-app>

</div>
</template>


<script>

import axios from "axios";
import NavBar from '../../../views/navbar.vue';
import SideBar from "../../../views/sideBar.vue";

export default {
  name: 'CustomerHome',
  data() {
    return {
      suggestion_name: "",
      old_name: "",
      currentUser: "",
      currentUserName: "",
      user_id: "",
      phone_number: "",
      pageName: "editSuggestion",
      selected_suggestion: "",
      short_description: "",
      suggestionId:"",
      isSuggestionClicked : false,
      show_status : "ACTIVE"

    }
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if(!this.currentUser){
      this.$router.push({
        name: "AdminLogin",
      });
    }
    else{
      var params = this.$route.params;
      var suggestion_id = params['suggestion_id'];
      this.suggestionId = suggestion_id;

      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        suggestionId : this.suggestionId,
      };
      axios.post(process.env.VUE_APP_BACKEND_URL + "/adminEditSuggestionPage", data)
      .then((successResponse) => {
        // var response_data = successResponse.data.data;
        this.suggestion_name = successResponse.data.data.suggestion;
        this.suggestion_key = successResponse.data.data.suggestion_key;
        this.short_description = successResponse.data.data.description;
        this.old_name = this.suggestion_name;
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
        if(errorResponse.response.status == 401){
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name : "AdminLogin"
          });
        }
        else if(errorResponse.response.status == 402){
          window.alert("Admin is Inactive...");
        }
        else{
          window.alert("Something went wrong...");
        }
      });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    redirectToViewAllSuggestions()
    {
     this.$router.push({
       name: "ViewAllSuggestions",
     });
   },
   gotoLoginPage() {
    var deleteData = {
     idToken : this.currentUser
   }
   axios.post(process.env.VUE_APP_BACKEND_URL + "/deleteAdminToken",deleteData)
   .then((deleteResponse) => {
     this.$cookies.remove("token");
   })
   .catch((error) => {
   })
   localStorage.clear();
   this.$router.push({
     name: "AdminLogin",
   });
 },
 editSuggestion()
 {
  this.isSuggestionClicked = true
  if(!this.suggestion_name)
  {
    alert("Please enter suggestion");
  }
  else
  {
    var suggestionEditbody = {
      "suggestionId" : this.suggestionId,
      "suggestion" : this.suggestion_name.trim(),
      "description" : this.short_description,
      "showStatus" : this.show_status,
      "token" : this.currentUser,
      "typeOfUser" : "ADMIN"
    }
    axios.post(process.env.VUE_APP_BACKEND_URL+"/updateSuggestion",suggestionEditbody)
    .then((modifySuggestionResponse) =>{
      alert("Suggestion edited");
      this.isSuggestionClicked = false
      this.redirectToViewAllSuggestions()
    })
    .catch((modifySuggestionError) => {
      this.isSuggestionClicked = false
      if(modifySuggestionError.response.status == 401){

        window.alert("Sorry !! You are unauthorized !!");
        this.$router.push({
          name : "AdminLogin"
        });
      }
      else if(modifySuggestionError.response.status == 403){
        window.alert("Please provide all the required fields");
      }
      else{
        window.alert("Something went wrong...");
      }
    });
  }
}
}
}
</script>

<style>

td,
th {
  padding:10px;
}
#facebook{
  display: none;
}
.EditSuggestionh3Class
{
  text-align:left;
  margin-left:65px;
  margin-top:30px;
  font-weight:800;
  font-size: 12pt;
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>